var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "banner-ratio banner-ratio-main",
      attrs: {
        to: _vm.tag === "router-link" && _vm.data.relative_url,
        href: _vm.data.link || false,
        target: (_vm.data.link && _vm.data.target) || false,
      },
      on: {
        click: function ($event) {
          return _vm.onClick($event, _vm.data.relative_url)
        },
      },
    },
    [
      _c(
        "base-picture",
        { staticClass: "banner-img", attrs: { lazy: _vm.lazy } },
        [
          _c("source", {
            attrs: {
              media: `(max-width: ${_vm.breakpoints.sm - 1}px)`,
              srcset: `
                ${_vm.getSrc(_vm.data.manifest.phone.image, "388x0")} 388w,
                ${_vm.data.manifest.phone.image}
            `,
              sizes: `
                (max-width: ${_vm.breakpoints.motoG4}px) 388px
            `,
            },
          }),
          _vm._v(" "),
          _c("source", {
            attrs: {
              media: `(min-width: ${_vm.breakpoints.sm}px)`,
              srcset: _vm.getSrc(_vm.data.manifest.desktop.image, "1200x342"),
            },
          }),
          _vm._v(" "),
          _c("base-img", {
            staticClass: "rounded",
            attrs: {
              lazy: _vm.lazy,
              src: _vm.getSrc(_vm.data.manifest.phone.image, "388x0"),
              alt: _vm.data.name,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }