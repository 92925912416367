<template>
    <component
        :is="tag"
        :to="tag === 'router-link' && data.relative_url"
        :href="data.link || false"
        :target="data.link && data.target || false"
        class="banner-ratio banner-ratio-main"
        @click="onClick($event, data.relative_url)"
    >
        <base-picture
            :lazy="lazy"
            class="banner-img"
        >
            <source
                :media="`(max-width: ${breakpoints.sm - 1}px)`"
                :srcset="`
                    ${getSrc(data.manifest.phone.image, '388x0')} 388w,
                    ${data.manifest.phone.image}
                `"
                :sizes="`
                    (max-width: ${breakpoints.motoG4}px) 388px
                `"
            >
            <source
                :media="`(min-width: ${breakpoints.sm}px)`"
                :srcset="getSrc(data.manifest.desktop.image, '1200x342')"
            >
            <base-img
                :lazy="lazy"
                :src="getSrc(data.manifest.phone.image, '388x0')"
                :alt="data.name"
                class="rounded"
            />
        </base-picture>
    </component>
</template>

<script>
import { BREAKPOINTS, BREAKPOINTS_DEVICES } from '../utils/constants';
import { scrollTo } from '../utils';
import BasePicture from './BasePicture.vue';
import BaseCountdown from './BaseCountdown.vue';

const breakpoints = {
    motoG4: BREAKPOINTS_DEVICES.motoG4,
    motoGPower: BREAKPOINTS_DEVICES.motoGPower,
    ...BREAKPOINTS,
};

export default {
    name: 'Banner',

    components: {
        BasePicture,
        BaseCountdown,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        tag() {
            let output = 'div';

            if (this.data.link) output = 'a';
            else if (this.data.relative_url) output = 'router-link';

            return output;
        },
    },

    created() {
        this.breakpoints = {
            motoG4: BREAKPOINTS_DEVICES.motoG4,
            ...BREAKPOINTS,
        };
    },

    methods: {
        onClick(event, wantedUrl) {
            const wantedUrlPath = wantedUrl.split('#');

            if (wantedUrlPath.length === 2 && wantedUrlPath[0] === this.$route.path) {
                event.preventDefault();
                scrollTo(wantedUrlPath[1]);
            }
        },

        getSrc: (url, size) => url.replace('.jpg', `.${size}.jpg`),

        scrollTo: (anchor) => {
            scrollTo(anchor);
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/05-components/banner";
</style>
